import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import ImageItem from "./ImageItem";
import DownloadIcon from "../images/svg/download.svg";
import DownloadWhiteIcon from "../images/svg/download-white.svg";
import PressReleases from "./PressReleases";
import { LanguageContext } from "../context";

const MediaCenter = () => {
  const { t } = useTranslation();
  const { getLanguage } = useContext(LanguageContext);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const { siteUrl } = data.site.siteMetadata;

  const DownloadsData = [
    // {
    //   title: t("projectdescription"),
    //   href: "/downloads/SAFIA_Projektbeschreibung_(Webpage).pdf",
    // },
    { title: t("rollup"), href: "/downloads/SAFIA_Rollup_(Webpage).pdf" },
    {
      title: t("onepager_de"),
      href: "/downloads/SAFIA-Technologies-Onepager_ger.pdf",
    },
    {
      title: t("onepager_en"),
      href: "/downloads/SAFIA-Technologies-Onepager_eng.pdf",
    },
  ];

  return (
    <Container backgroundColor="transparent">
      <h2 className="text-h2mobile sm:text-h2desktop text-white">
        {t("mediaCenter")}
      </h2>
      <div className="mt-15">
        <PressReleases />
      </div>
      <div className="mt-100px">
        <h3 className="text-h3mobile sm:text-h3desktop text-white">
          {t("images")}
        </h3>
        <div className="flex flex-row flex-wrap mt-5 justify-start">
          {/* <ImageItem
            url={`${siteUrl}/downloads/01.jpg`}
            alt="Das Safia-Team im Labor"
          /> */}

          <ImageItem
            url={`${siteUrl}/downloads/02.jpg`}
            alt="Das Safia-Team im Labor"
          />
          <ImageItem
            url={`${siteUrl}/downloads/03.jpg`}
            alt="Das Safia-Team im Labor"
          />
        </div>
      </div>
      <div className="mt-100px">
        <h3 className="text-h3mobile sm:text-h3desktop text-white">
          {t("videos")}
        </h3>
        <div className="flex flex-row flex-wrap mt-5 justify-start gap-2">
          <iframe
            src={getLanguage() === "DE" ? "https://www.youtube.com/embed/OUcR9I0S_I0" : "https://www.youtube.com/embed/P8BoYHfe0xs"}
            width="100%"
            height="315"
            className="max-w-md"
            frameborder="0"
            allowfullscreen
          />
          <iframe
            src={getLanguage() === "DE" ? "https://www.youtube.com/embed/pdz1MpC_2og" : "https://www.youtube.com/embed/w-Rc4Aj3Ez4"}
            width="100%"
            height="315"
            className="max-w-md"
            frameborder="0"
            allowfullscreen
          />
        </div>
      </div>
      <div id="downloadarea" className="mt-100px pt-20">
        <div className="flex flex-row flex-wrap">
          <div className="w-full md:w-7/12">
            <h3 className="text-h3mobile sm:text-h3desktop text-white">
              {t("downloadArea")}
            </h3>
            <div className="mt-10">
              {DownloadsData.map(({ title, href }) => (
                <div
                  key={title}
                  className="flex flex-row mb-5 items-start text-white hover:text-screamingGreen"
                >
                  <DownloadIcon
                    style={{ marginTop: "1px" }}
                    className="mr-10px"
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecorationThickness: "2px" }}
                    href={`${siteUrl}${href}`}
                    className="text-body hover:text-screamingGreen mr-10px underline greenunderline cursor-pointer"
                  >
                    {title}
                  </a>
                  <span className="text-body">[pdf]</span>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-5/12 mt-20 md:mt-0 flex">
            <div
              className="bg-palatinateBlue"
              style={{ width: "330px", padding: "30px" }}
            >
              <div style={{ height: "52px" }}>
                <h3 className="text-h3mobile sm:text-h3desktop text-white font-safiaBold">
                  Logo
                </h3>
              </div>
              <div
                className="text-body text-white mb-6"
                style={{ width: "270px" }}
              >
                {t("logoDesc")}
              </div>
              <div className="flex flex-row mb-5 items-start text-white hover:text-screamingGreen">
                <DownloadWhiteIcon
                  style={{ marginTop: "1px" }}
                  className="mr-10px"
                />
                <a
                  href={`${siteUrl}/downloads/logos.zip`}
                  style={{ textDecorationThickness: "2px" }}
                  className="text-body hover:text-screamingGreen mr-10px underline whiteunderline cursor-pointer"
                >
                  Logos
                </a>
                <span className="text-body">[zip]</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MediaCenter;
