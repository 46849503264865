import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { graphql, Link, StaticQuery } from "gatsby";
import { format } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { LanguageContext } from "../context";

const PressReleases = () => {
  const { t } = useTranslation();
  const { getLanguage } = useContext(LanguageContext);

  return (
    <Fragment>
      <h3 className="text-h3mobile sm:text-h3desktop text-white">
        {t("pressReleases")}
      </h3>
      {/* <p className="text-white">{t("pressReleaseEmpty")}</p> */}
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark(
              filter: { frontmatter: { templateKey: { eq: "pressrelease" } } }
              sort: { fields: [frontmatter___date], order: DESC }
            ) {
              edges {
                node {
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    date
                    author
                    language
                    templateKey
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          let PRESSRELEASE_DE = [];
          let PRESSRELEASE_EN = [];

          data.allMarkdownRemark.edges.forEach((edge) => {
            const { language } = edge.node.frontmatter;
            if (language === "de") {
              PRESSRELEASE_DE.push(edge);
            } else if (language === "en") {
              PRESSRELEASE_EN.push(edge);
            }
            return;
          });

          if (
            (getLanguage() === "DE" && PRESSRELEASE_DE.length === 0) ||
            (getLanguage() === "EN" && PRESSRELEASE_EN.length === 0)
          ) {
            return (
              <Fragment>
                <p className="text-white">{t("pressReleaseEmpty")}</p>
              </Fragment>
            );
          }
          console.log("DATA", data);
          return (
            <Fragment>
              {data.allMarkdownRemark.edges.map((pressRelease, index) => {
                const {
                  date,
                  title,
                  templateKey,
                  language,
                } = pressRelease.node.frontmatter;
                const { slug } = pressRelease.node.fields;
                if (language.toLowerCase() === getLanguage().toLowerCase()) {
                  return (
                    <div
                      className="flex flex-row items-center"
                      style={
                        index === 0
                          ? { marginTop: "30px", marginBottom: "22px" }
                          : { marginTop: "22px" }
                      }
                      key={`press-release-${title}`}
                    >
                      <span className="text-white text-body mr-6">
                        {format(new Date(date), "dd.MMMM.yyyy", {
                          locale: getLanguage() === "DE" ? de : enUS,
                        })}
                      </span>

                      <Link
                        to={`/${templateKey}/${slug}`}
                        className="text-white text-body"
                      >
                        {title}
                      </Link>
                    </div>
                  );
                }
              })}
            </Fragment>
          );
        }}
      />
    </Fragment>
  );
};

export default PressReleases;
