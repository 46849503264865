import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { de, enUS } from "date-fns/locale";
import Container from "./Container";
import BlogItem from "./BlogItem";
import { LanguageContext } from "../context";

const BlogSection = () => {
  const { t } = useTranslation();
  const { getLanguage } = useContext(LanguageContext);

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "blog" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              language
              templateKey
              date
              description
              author
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 240, maxHeight: 140, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let NEWS_DE = [];
  let NEWS_EN = [];

  data.allMarkdownRemark.edges.forEach((edge) => {
    const { language } = edge.node.frontmatter;
    if (language === "de") {
      NEWS_DE.push(edge);
    } else if (language === "en") {
      NEWS_EN.push(edge);
    }
    return;
  });

  return (
    <Container backgroundColor="transparent">
      <h2 className="text-h2mobile sm:text-h2desktop text-white">
        {t("articles")}
      </h2>
      {(getLanguage() === "DE" && NEWS_DE.length === 0) ||
        (getLanguage() === "EN" && NEWS_EN.length === 0 && (
          <p className="text-white mt-5">{t("blogEmpty")}</p>
        ))}
      <div className="flex flex-row flex-wrap mt-5 justify-center sm:justify-start">
        {data.allMarkdownRemark.edges.map((edge) => {
          const {
            title,
            language,
            templateKey,
            description,
            thumbnail,
            date,
            author,
          } = edge.node.frontmatter;
          const { slug } = edge.node.fields;
          if (language.toLowerCase() === getLanguage().toLowerCase()) {
            return (
              <BlogItem
                key={title}
                to={`/blog/${slug}`}
                date={format(new Date(date), "dd.MMMM.yyyy", {
                  locale: getLanguage() === "DE" ? de : enUS,
                })}
                title={title}
                description={description}
                thumbnail={thumbnail}
                author={author}
              />
            );
          }
        })}

        {/* <BlogItem />
        <BlogItem />
        <BlogItem />
        <BlogItem />
        <BlogItem />
        <BlogItem />
        <BlogItem /> */}
      </div>
    </Container>
  );
};

export default BlogSection;
