/* eslint-disable import/first */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Particles from "react-tsparticles";
import scrollTo from "gatsby-plugin-smoothscroll";

const throttle = require("lodash/throttle");

import ParticleConfig from "../particles/particleconfigSubpage";
import NewsletterSubscribe from "../components/NewsletterSubscribe";
import NonBreakingSpaceText from "../components/NonBreakingSpaceText";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ScrollDownButton from "../components/ScrollDownButton";
import ArrowRight from "../images/svg/arrow-right.svg";
import BlogSection from "../components/BlogSection";
import NewsAndEvents from "../components/NewsAndEvents";
import MediaCenter from "../components/MediaCenter";

const AboutButton = ({ name, onClick }) => {
  return (
    <div className="flex items-start self-start relative mb-6">
      <div
        onClick={onClick}
        className="flex flex-row items-center self-start buttonarrow cursor-pointer"
      >
        <span className="font-safiaBold text-s18 text-screamingGreen block">
          {name}
        </span>
        <ArrowRight />
      </div>
    </div>
  );
};

const AboutPage = () => {
  const { t } = useTranslation();
  const particleDiv = useRef(null);
  const [particleHeight, setParticleHeight] = useState(973);

  useEffect(() => {
    const resize = throttle(() => {
      setParticleHeight(particleDiv.current.clientHeight + 72);
    }, 200);

    setParticleHeight(particleDiv.current.clientHeight + 72);
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [particleDiv]);

  return (
    <Layout>
      <SEO
        title={t("about")}
        meta={[
          {
            name: "cache-control",
            content: "public, max-age=0, must-revalidate",
          },
        ]}
      />
      <div
        style={{
          position: "relative",
          top: 0,
          left: 0,
        }}
      >
        <Particles
          height={particleHeight}
          className="bg-safiaBlue"
          params={ParticleConfig}
        />
        <div ref={particleDiv} className="absolute top-0 w-full">
          <div className="container mx-auto px-8 pb-20 pt-topHeader">
            <div className="flex flex-row flex-wrap lg:justify-between">
              <div className="w-full lg:w-7/12">
                <h1 className="text-h1mobile sm:text-h1desktop">
                  {t("aboutTitle")}
                </h1>
                <h2
                  className="text-body sm:text-h2desktop"
                  style={{
                    marginTop: "30px",
                    fontFamily: "Regular",
                  }}
                >
                  <NonBreakingSpaceText text={t("aboutDesc")} />
                </h2>
              </div>
              <div className="w-full mt-10 lg:mt-0 lg:w-3/12 pt-6 pb-6">
                <AboutButton
                  name={t("articles")}
                  onClick={() => scrollTo("#berichte")}
                />
                <AboutButton
                  name="News & Events"
                  onClick={() => scrollTo("#newsandevents")}
                />
                <AboutButton
                  name={t("mediaCenter")}
                  onClick={() => scrollTo("#mediacenter")}
                />
                <AboutButton
                  name={t("downloadArea")}
                  onClick={() => scrollTo("#downloadarea")}
                />
              </div>
            </div>
            <ScrollDownButton selector="#berichte" />
          </div>
          <div id="berichte">
            <BlogSection />
          </div>
          <div id="newsandevents">
            <NewsAndEvents />
          </div>
          <div id="mediacenter">
            <MediaCenter />
          </div>
        </div>
        {/* <NewsletterSubscribe /> */}
      </div>
    </Layout>
  );
};

export default AboutPage;
