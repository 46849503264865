import React from "react";
import PropTypes from "prop-types";
import scrollTo from "gatsby-plugin-smoothscroll";

import ArrowDown from "../images/svg/scrolldown.svg";

const ScrollDownButton = ({ selector, marginTop }) => (
  <div className={`flex justify-center items-center ${marginTop} w-full mt-`}>
    <button onClick={() => scrollTo(selector)}>
      <div className="flex items-center justify-center h-13 w-13">
        <ArrowDown />
      </div>
    </button>
  </div>
);

ScrollDownButton.propTypes = {
  selector: PropTypes.string.isRequired,
  marginTop: PropTypes.string,
};

ScrollDownButton.defaultProps = {
  marginTop: "mt-16 md:mt-24",
};

export default ScrollDownButton;
