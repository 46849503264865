import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "./Container";
import EventItem from "./EventItem";
import { LanguageContext } from "../context";

const NewsAndEvents = () => {
  const { getLanguage } = useContext(LanguageContext);
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "event" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              date
              author
              language
              city
              templateKey
            }
          }
        }
      }
    }
  `);

  let NEWS_DE = [];
  let NEWS_EN = [];

  data.allMarkdownRemark.edges.forEach((edge) => {
    const { language } = edge.node.frontmatter;
    if (language === "de") {
      NEWS_DE.push(edge);
    } else if (language === "en") {
      NEWS_EN.push(edge);
    }
    return;
  });

  if (
    (getLanguage() === "DE" && NEWS_DE.length === 0) ||
    (getLanguage() === "EN" && NEWS_EN.length === 0)
  ) {
    return null;
  }

  return (
    <Container backgroundColor="transparent">
      <h2 className="text-h2mobile sm:text-h2desktop text-white">
        News & Events
      </h2>
      <div className="flex flex-row flex-wrap mt-3 justify-center sm:justify-start">
        {data.allMarkdownRemark.edges.map((edge) => {
          const { title, date, city, language } = edge.node.frontmatter;
          const { slug } = edge.node.fields;
          if (language.toLowerCase() === getLanguage().toLowerCase()) {
            return (
              <EventItem
                key={title}
                title={title}
                to={`/event/${slug}`}
                date={date}
                city={city}
              />
            );
          }
        })}
      </div>
    </Container>
  );
};

export default NewsAndEvents;
