import React from "react";
import DownloadIcon from "../images/svg/download.svg";

const ImageItem = ({ url, alt }) => (
  <div
    className="w-full mr-0 sm:mr-30px sm:w-331px"
    style={{ height: "277px", marginTop: "30px" }}
  >
    <div style={{ height: "234px", width: "100%" }} className="bg-gainsboro">
      <img
        style={{ width: "100%", height: "234px", objectFit: "cover" }}
        src={url}
        alt={alt}
      />
    </div>
    <div className="flex flex-row mt-10px items-start text-white">
      <DownloadIcon style={{ marginTop: "1px" }} className="mr-10px" />
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="text-body hover:text-screamingGreen mr-10px underline greenunderline cursor-pointer"
      >
        Bildtitel
      </a>
      <span className="text-body">[jpg]</span>
    </div>
  </div>
);

export default ImageItem;
