import React, { useContext } from "react";
import { Link } from "gatsby";
import { format } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { LanguageContext } from "../context";

const EventItem = ({ title, date, to, city }) => {
  const { getLanguage } = useContext(LanguageContext);
  return (
    <Link to={to} className="no-underline mr-0 sm:mr-30px mt-30px">
      <div
        className={`flex flex-col justify-between eventitem cursor-pointer`}
        style={{
          height: "140px",
          width: "240px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "14px",
          paddingTop: "20px",
        }}
      >
        <h4 className="noselect text-h4mobile sm:text-h4desktop mb-0">
          {title}
        </h4>
        <span className="noselect text-s15 font-safiaBold mt-1">
          {`${format(new Date(date), "dd.MMMM.yyyy", {
            locale: getLanguage() === "DE" ? de : enUS,
          })}, ${city}`}
        </span>
      </div>
    </Link>
  );
};

export default EventItem;
